import { tags as t } from '@lezer/highlight'
import { HighlightStyle } from '@codemirror/language'
import { EditorView } from '@codemirror/view'
import { colors } from 'styles/vars/colors.style'
import { font } from 'styles/vars/font.style'

export const syntaxStyles = HighlightStyle.define([
  { tag: t.keyword, color: colors.lavender },
  { tag: [t.propertyName], color: colors.lavender },
  {
    tag: [t.processingInstruction, t.string, t.inserted, t.special(t.string)],
    color: colors.lightLime,
  },
  { tag: [t.function(t.variableName)], color: colors.lightOrange },
  { tag: [t.definition(t.name), t.separator], color: colors.salmonCream },

  { tag: [t.operator], color: colors.salmonCream },
  { tag: [t.bracket], color: colors.salmonCream },
  { tag: [t.inserted], color: colors.salmonCream },
])

export const theme = EditorView.theme(
  {
    '&': {
      color: colors.roses,
      fontFamily: font.secondary,
      fontSize: '1.2rem',
      fontWeight: 400,
      lineHeight: 1.1,
      letterSpacing: '0.1px',
    },
    '.cm-gutters': {
      display: 'none',
    },
    '.cm-activeLine': {
      background: 'transparent',
    },
    '.cm-cursor': {
      borderLeftColor: colors.neutralWhite,
    },
    '&.cm-focused .cm-selectionBackground, .cm-selectionMatch, ::selection': {
      backgroundColor: colors.humeBlack700,
    },
    '.cm-content': {
      padding: '1.2rem',
    },
    '.cm-line': {
      padding: '0',
    },
  },
  { dark: true }
)
