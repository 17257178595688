import React, { useMemo } from 'react';
import styled from 'styled-components';

import { mq } from 'styles/vars/media-queries.style';
import { colors } from 'styles/vars/colors.style';
import { breakpoints } from 'styles/vars/breakpoints.style';
import { clamp } from 'styles/utils/conversion.style';

import Particles from 'components/Particles';
import { Heading2, TextBodyLarge, TextCTA } from 'components/TextStyles';
import ContentfulButton from 'components/ContentfulButton';

const SolutionsHero = ({
  title,
  text,
  buttons,
  image,
  filterData,
  filterClick,
}) => {
  const buttonEls = useMemo(
    () =>
      buttons.map((item, index) => {
        return <ContentfulButton key={index} data={item} />;
      }),
    [buttons],
  );

  const FilterEls = filterData.map((item, index) => {
    return (
      <Filter key={index} onClick={(e) => filterClick(e, item)}>
        <TextCTA color={colors.humeTan400}>{item}</TextCTA>
      </Filter>
    );
  });

  return (
    <Wrapper>
      <Particles
        dark={true}
        fade={false}
        gap={4}
        scale={13}
        xTarget={-50}
        yTarget={15}
        y={6.94}
        z={13}
        zDepth={40}
      />
      <Inner>
        <Nav>
          <Filters>{FilterEls}</Filters>
        </Nav>
        <Content>
          <Title>
            <Heading2 as="h1" color={colors.humeTan400}>
              {title}
            </Heading2>
          </Title>
          <Text>
            <TextBodyLarge color={colors.humeTan400}>{text}</TextBodyLarge>
          </Text>
          <ButtonRow>{buttonEls}</ButtonRow>
        </Content>
        <Image src={image.file.url} alt="ui illustration" />
      </Inner>
    </Wrapper>
  );
};

export default SolutionsHero;

const Wrapper = styled.div`
  background: ${colors.humeBlack700};
  position: relative;
  overflow: hidden;

  min-height: 1081px;

  ${mq.tabletP} {
    min-height: 1168px;
  }

  ${mq.desk} {
    min-height: 1185px;
  }

  ${mq.contained} {
    min-height: 61.72vw;
  }
`;

const Inner = styled.div`
  position: relative;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 156px 22px;

  ${mq.tabletP} {
    padding: 266px 100px;
  }

  ${mq.desk} {
    padding: unset;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 60px;

  ${mq.tabletP} {
    margin-bottom: 80px;
  }

  ${mq.desk} {
    position: absolute;
    transform: unset;
    top: 366px;
    left: 133px;
  }

  ${mq.contained} {
    top: 19.06vw;
    left: 6.93vw;
  }
`;

const Title = styled.div`
  text-align: center;
  width: 100%;

  margin-bottom: 20px;

  ${mq.tabletP} {
    width: 656px;
  }

  ${mq.desk} {
    text-align: left;
    width: 600px;
  }

  ${mq.contained} {
    width: 31.25vw;
    margin-bottom: 1.04vw;
  }
`;

const Text = styled.div`
  text-align: center;
  width: 100%;

  margin-bottom: 40px;

  ${mq.tabletP} {
    width: 656px;
  }

  ${mq.desk} {
    text-align: left;
    width: 600px;
  }

  ${mq.contained} {
    width: 31.25vw;
    margin-bottom: 2.08vw;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  gap: 20px;

  ${mq.tabletP} {
    flex-direction: row;
  }

  ${mq.desk} {
    justify-content: flex-start;
  }

  ${mq.contained} {
    gap: 1.04vw;
  }
`;

const Nav = styled.div`
  position: absolute;
  border-top: 1px solid ${colors.humeTan700};

  justify-content: center;

  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  width: 100%;
  height: 40px;
  gap: 25px;
  top: 77px;

  ${mq.tabletP} {
    height: 50px;
    top: 106px;
  }

  ${mq.desk} {
    top: 108px;
  }

  ${mq.contained} {
    top: 5.5vw;
    height: 2.6vw;
  }
`;

const Filters = styled.div`
  margin: auto;
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: flex-end;
  gap: 3rem;

  ${clamp('padding-left', 22, 50)}
  ${clamp('padding-right', 22, 50)}
`;

const Filter = styled.div`
  min-width: fit-content;
  cursor: pointer;
  text-transform: uppercase;
  text-wrap: nowrap;
`;

const Image = styled.img`
  max-width: unset;
  align-self: flex-start;

  max-width: 607.5px;
  max-height: 380.25px;
  width: 162vw;
  height: 101.4vw;

  ${mq.tabletP} {
    max-width: unset;
    max-height: unset;
    align-self: center;
    width: 79.1vw;
    height: 49.51vw;
  }

  /* ${clamp('width', 607.5, 810, breakpoints.mobile, breakpoints.tabletL)}; */
  /* ${clamp(
    'height',
    380.25,
    507,
    breakpoints.mobile,
    breakpoints.tabletL,
  )}; */

  ${mq.desk} {
    position: absolute;
    ${clamp('left', 796, 973, breakpoints.desk, breakpoints.deskL)};
    ${clamp('width', 960, 1440, breakpoints.desk)};
    ${clamp('height', 650, 900, breakpoints.desk)};
    top: 269px;
  }

  ${mq.contained} {
    width: 75vw;
    height: 46.88vw;
    left: 50.68vw;
    top: 14vw;
  }
`;
