import styled, { keyframes } from 'styled-components';
import Image from 'gatsby-image';
import { clamp } from 'styles/utils/conversion.style';
import { mq } from 'styles/vars/media-queries.style';
import { TextStyle } from 'styles/vars/textStyles.style';
import { font } from 'styles/vars/font.style';
import { colors } from 'styles/vars/colors.style';
import { easings } from 'styles/vars/easings.style';

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 88.8rem;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 6rem;

  ${mq.tabletP} {
    margin-bottom: 8rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;

  ${mq.tabletP} {
    display: grid;
    grid-template-columns: minmax(min-content, 35%) minmax(0, 1fr);
    grid-template-areas:
      'tabsList tabsContent'
      'buttons buttons';
    gap: 5.4rem;
  }

  ${mq.desk} {
    grid-template-areas:
      'tabsList tabsContent'
      'buttons tabsContent';
    gap: 11rem;
  }
`;

export const TabsList = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 7rem;

  ${mq.tabletP} {
    flex-direction: column;
    justify-content: flex-start;
    grid-area: tabsList;
  }
`;

export const Tab = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  color: ${colors.humeTan400};
  background-color: transparent;
  border: none;
  cursor: pointer;
  opacity: ${({ active }) => (active ? 1 : 0.3)};
  transition: opacity 0.3s ${easings.text.in};

  ${mq.tabletP} {
    display: grid;
    grid-template:
      'icon title'
      '. description';
    grid-template-columns: 4rem 1fr;
    column-gap: 2rem;
  }

  &:hover {
    opacity: ${({ active }) => (active ? 1 : 0.7)};
  }
`;

export const IconWrapper = styled.div`
  width: 4.5rem;
  height: auto;

  ${mq.tabletP} {
    grid-area: icon;
    width: 4rem;
  }
`;

export const Icon = styled.img`
  max-width: 100%;
  height: auto;
`;

export const TabTitle = styled.span`
  ${TextStyle.TextBody}
  font-weight: ${font.weight.medium};
  text-align: left;

  ${mq.tabletP} {
    ${TextStyle.TextBodyLarge}
    grid-area: title;
  }
`;

export const Description = styled.span`
  ${TextStyle.TextBody}
  color: ${colors.humeTan400};
  text-align: center;

  ${mq.tabletP} {
    grid-area: description;
    text-align: left;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 2rem;
  order: 2;

  ${mq.tabletP} {
    grid-area: buttons;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin: auto;
  }

  ${mq.desk} {
    margin-top: 2rem;
  }
`;

export const TabsContent = styled.div`
  ${mq.tabletP} {
    grid-area: tabsContent;
  }
`;

export const TabsPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
`;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 2rem;
  border-radius: 5px;
  border: 0.05rem solid ${colors.humeBlack100};
  background: ${colors.humeBlack800};

  ${mq.tabletP} {
    flex-direction: column;
    align-items: flex-start;
  }

  ${mq.desk} {
    flex-direction: row;
    align-items: center;
  }
`;

export const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1.2rem;

  ${({ $gridItems }) => {
    switch ($gridItems) {
      case 3:
        return `
            grid-template-rows: repeat(2, min-content);
            &>*:nth-child(2) {
              grid-area: 1 / 2 / 3 / 3;
            }
          `;
      case 2:
      default:
        return `
            grid-template-rows: 1fr;
          `;
    }
  }}
`;

const ImageWrapperAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const ImageWrapper = styled.div`
  border-radius: 0.4rem;
  overflow: hidden;
  background: white;

  > * {
    animation: ${ImageWrapperAnimation} 0.5s ease;
  }
`;

export const Img = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
