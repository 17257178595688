import React, { useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { gsap, Power3 } from 'gsap';
import AnimateSplitText from 'components/_animation/AnimateSplitText';
import ContentfulButton from 'components/ContentfulButton';
import CodeBox from 'components/CodeBox';
import Button from '../Button';
import { buttonThemes, buttonVariants } from '../Button/index.style';
import IconArrowheadRight from '../_svgs/IconArrowheadRight';
import { colors } from 'styles/vars/colors.style';
import { Heading2, TextCTA, TextBodySmall } from 'components/TextStyles';
import { useMedia } from 'hooks/useMedia';
import {
  Title,
  Content,
  TabsList,
  Tab,
  IconWrapper,
  Icon,
  TabTitle,
  Description,
  ButtonsContainer,
  TabsContent,
  TabsPanel,
  Banner,
  ImagesContainer,
  ImageWrapper,
  Img,
} from './index.style';
import SectionWrapper from '../_layouts/SectionWrapper';

const HomeBuild = ({ kickerText, title, data, buttons }) => {
  const mobileOnly = useMedia(
    false,
    false,
    false,
    false,
    false,
    true,
    true,
    true,
  );

  const tabletOnly = useMedia(
    false,
    false,
    false,
    false,
    true,
    true,
    true,
    true,
  );

  const [activeTab, setActiveTab] = useState(0);
  const cardRef = useRef(null);
  const activeData = data[activeTab];

  useLayoutEffect(() => {
    gsap.to(cardRef.current, {
      opacity: 1,
      duration: 0.3,
      ease: Power3.easeInOut,
    });
  }, [activeTab]);

  const handleTabClick = (index) => {
    if (index !== activeTab) setActiveTab(index);
  };

  return (
    <SectionWrapper dark={true}>
      <Title>
        <TextCTA color={colors.humeBlack100}>{kickerText}</TextCTA>
        <Heading2 as="h3" color={colors.humeTan400}>
          <AnimateSplitText>{title}</AnimateSplitText>
        </Heading2>
      </Title>
      <Content>
        <TabsList role="tablist" aria-label="Build products">
          {data.map((tab, i) => (
            <Tab
              key={`tab-${i}`}
              active={i === activeTab}
              onClick={() => handleTabClick(i)}
              role="tab"
              id={tab.title}
              aria-selected={i === activeTab}
              aria-controls={tab.title}
              tabindex={i === activeTab ? '0' : '-1'}
            >
              <IconWrapper>
                <Icon src={tab.icon.file.url} alt={tab.icon.file.title} />
              </IconWrapper>
              <TabTitle>{tab.title}</TabTitle>
              {!mobileOnly && <Description>{tab.description}</Description>}
            </Tab>
          ))}

          {!tabletOnly && buttons && buttons.length && (
            <ButtonsContainer>
              {buttons.map((button, i) => (
                <ContentfulButton data={button} key={`button-${i}`} />
              ))}
            </ButtonsContainer>
          )}
        </TabsList>
        {mobileOnly && <Description>{activeData.description}</Description>}
        {tabletOnly && buttons && buttons.length && (
          <ButtonsContainer>
            {buttons.map((button, i) => (
              <ContentfulButton data={button} key={`button-${i}`} />
            ))}
          </ButtonsContainer>
        )}
        <TabsContent ref={cardRef}>
          {data.map((content, i) => (
            <TabsPanel
              key={`tab-panel-${i}`}
              role="tabpanel"
              id={content.title}
              tabindex="0"
              aria-labelledby={content.title}
              hidden={i !== activeTab}
            >
              <Banner>
                <TextBodySmall color={colors.humeBlack100}>
                  {content.text}
                </TextBodySmall>
                <Button
                  variant={buttonVariants.outline}
                  theme={buttonThemes.light}
                  size="small"
                  iconRight={<IconArrowheadRight />}
                  onClick={() =>
                    activeTab < 2
                      ? handleTabClick(activeTab + 1)
                      : handleTabClick(0)
                  }
                >
                  Next
                </Button>
              </Banner>
              <ImagesContainer $gridItems={content.images.length}>
                {content.images.map((image, i) => (
                  <ImageWrapper key={`img-${i}`}>
                    <Img fluid={image.fluid} />
                  </ImageWrapper>
                ))}
              </ImagesContainer>
              <CodeBox code={content.codeSnippet.codeSnippet} />
            </TabsPanel>
          ))}
        </TabsContent>
      </Content>
    </SectionWrapper>
  );
};

export default HomeBuild;

HomeBuild.propTypes = {
  kickerText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  buttons: PropTypes.arrayOf(PropTypes.object),
};
