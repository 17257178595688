import React, { useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { colors } from 'styles/vars/colors.style';
import { ReactComponent as LogoDotsColor } from 'images/logo-dots-color.svg';
import { ReactComponent as Copy } from 'images/copy.svg';
import { TextBodyXSmall } from 'components/TextStyles';
import { EditorView } from '@codemirror/view';
import { basicSetup } from 'codemirror';
import { EditorState, Compartment } from '@codemirror/state';
import { syntaxHighlighting } from '@codemirror/language';
import { python } from '@codemirror/lang-python';
import { theme, syntaxStyles } from 'styles/partials/codemirrorTheme.style';

const CodeBox = ({ code }) => {
  const view = useRef(null);
  const container = useRef(null);
  let language = new Compartment();

  const doc = useRef(
    EditorState.create({
      doc: code,
      extensions: [
        basicSetup,
        language.of(python()),
        syntaxHighlighting(syntaxStyles),
        theme,
        EditorState.readOnly.of(true),
      ],
    }),
  );

  const copyToClipboard = useCallback((e) => {
    e.preventDefault();
    const codeSnippet = doc.current?.doc.toString() ?? '';
    if (!codeSnippet) return;
    void navigator.clipboard.writeText(codeSnippet);
  }, []);

  useEffect(() => {
    if (!container.current) return;

    const state = doc.current;
    const parent = container.current;

    const editorView = (view.current = new EditorView({
      state,
      parent,
    }));

    return () => {
      editorView.destroy();
      view.current = null;
    };
  }, []);

  return (
    <Wrapper>
      <TopBar>
        <TextBodyXSmall color={colors.humeTan400}>
          Request (Python)
        </TextBodyXSmall>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
      </TopBar>
      <CodeEditor ref={container} />
      <BottomBar>
        <CopyButton onClick={copyToClipboard}>
          <IconCopy />
        </CopyButton>
      </BottomBar>
    </Wrapper>
  );
};

export default CodeBox;

export const Wrapper = styled.div`
  border-radius: 0.5rem;
  border: 0.05rem solid ${colors.humeBlack100};
  background-color: ${colors.humeBlack800};
`;

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.05rem solid ${colors.humeBlack100};
  padding: 1.2rem;
`;

export const LogoWrapper = styled.div`
  width: 1.5rem;
  height: auto;
`;

export const Logo = styled(LogoDotsColor)`
  width: 100%;
  height: 100%;
`;

export const CodeEditor = styled.div``;

export const BottomBar = styled(TopBar)`
  justify-content: flex-end;
  border-top: 0.05rem solid ${colors.humeBlack100};
  border-bottom: none;
`;

export const CopyButton = styled.button`
  width: 1rem;
  height: auto;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const IconCopy = styled(Copy)`
  width: 100%;
  height: 100%;
`;
