import React, { useEffect, useState, useCallback, useContext } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import gsap from 'gsap';
import { ContextState } from 'components/Providers';

import { colors } from 'styles/vars/colors.style';
import { scrollToSection } from 'utils/utils';
import SEO from 'components/SEO';
import Footer from 'components/Footer';
import HomeBuild from 'components/HomeBuild';
import HomeProduct from 'components/HomeProduct';
import SolutionsHero from 'components/SolutionsHero';
import SolutionsFeatures from 'components/SolutionsFeatures';
import ScrollSection from 'components/ScrollSection';

// eslint-disable-next-line no-unused-vars
import { ScrollToPlugin } from 'gsap/all';
gsap.registerPlugin(ScrollToPlugin);

const IndexPage = (props) => {
  const {
    metaDescription,
    title,
    heroTitle,
    heroText,
    heroButtons,
    heroImage,
    filters,
    featuresKickerText,
    featuresTitle,
    featuresText,
    features,
  } = props.data.allContentfulPageProductsV2.nodes[0];

  const {
    buildKickerText,
    buildTitle,
    buildTabs,
    buildButtons,
  } = props.data.allContentfulPageHomeV2.nodes[0];

  const {
    productsTitle,
    productsKicker,
    productsText,
    expressionMeasurementLabel,
    expressionMeasurementSolutions,
    customModelsLabel,
    customModelsSolutions,
  } = props.data.allContentfulHomeV2BDec8.nodes[0];

  const [scrolled, setScrolled] = useState(false);
  const { scrollSection } = useContext(ContextState);

  const handleFilterClick = useCallback((e, filter) => {
    const $activeContent = document.querySelector(
      `[data-filter-section="${filter}"]`,
    );
    if (e) {
      e.preventDefault();
    }

    scrollToSection($activeContent);
  }, []);

  useEffect(() => {
    if (scrollSection && !scrolled) {
      handleFilterClick(null, scrollSection);
      // to avoid scrolling when transitioning away from products page
      setScrolled(true);
    }
  }, [scrollSection, handleFilterClick, scrolled, setScrolled]);

  return (
    <ProductsWrapper>
      <SEO
        title={title}
        description={metaDescription.metaDescription}
        pathname={`products`}
        noIndex
      />
      <ScrollSection>
        <SolutionsHero
          title={heroTitle}
          text={heroText}
          buttons={heroButtons}
          image={heroImage}
          filterData={filters}
          filterClick={handleFilterClick}
        />
      </ScrollSection>

      <ScrollSection>
        <Line />
      </ScrollSection>

      <ScrollSection data-filter-section={filters[0]}>
        <SolutionsFeatures
          kicker={featuresKickerText}
          title={featuresTitle}
          text={featuresText}
          features={features}
        />
      </ScrollSection>

      <ScrollSection data-filter-section={filters[1]}>
        <HomeProduct
          title={productsTitle}
          text={productsText}
          kickerText={productsKicker}
          rowOneLabel={expressionMeasurementLabel}
          rowOneItems={expressionMeasurementSolutions}
          rowTwoLabel={customModelsLabel}
          rowTwoItems={customModelsSolutions}
        />
      </ScrollSection>

      <ScrollSection data-filter-section={filters[2]}>
        <HomeBuild
          kickerText={buildKickerText}
          title={buildTitle}
          data={buildTabs}
          buttons={buildButtons}
        />
      </ScrollSection>

      <Footer />
    </ProductsWrapper>
  );
};

export default IndexPage;

const Line = styled.div`
  height: 1px;
  background: ${colors.humeTan700};
`;

const ProductsWrapper = styled.div``;

export const pageQuery = graphql`
  query PageProductsV2 {
    allContentfulPageProductsV2 {
      nodes {
        title
        metaDescription {
          metaDescription
        }
        heroTitle
        heroText
        heroButtons {
          ...ButtonQuery
        }
        heroImage {
          ...ImageFileQuery
        }
        filters
        featuresKickerText
        featuresTitle
        featuresText
        features {
          ...FeatureQuery
        }
        classificationApiTitle
        classificationApiKickerText
        classificationApiExamplesTitle
        classificationApiExamplesButton {
          ...ButtonQuery
        }
        classificationApiExamples {
          ...SolutionPreviewQuery
        }
        expressionTitle
        expressionText {
          expressionText
        }
        expressionModels {
          ...SolutionPreviewQuery
        }
      }
    }
    allContentfulPageHomeV2 {
      nodes {
        buildKickerText
        buildTitle
        buildTabs {
          title
          icon {
            file {
              url
            }
            title
          }
          description
          images {
            title
            fluid(maxWidth: 1600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          text
          button {
            ...ButtonQuery
          }
          codeSnippet {
            codeSnippet
          }
        }
        buildButtons {
          ...ButtonQuery
        }
        latestsModelsTitle
        latestModelsButton {
          ...ButtonQuery
        }
        latestModels {
          ...SolutionPreviewQuery
        }
      }
    }

    allContentfulHomeV2BDec8 {
      nodes {
        productsKicker
        productsTitle
        productsText
        expressionMeasurementLabel
        expressionMeasurementSolutions {
          ...SolutionPreviewQuery
        }
        customModelsLabel
        customModelsSolutions {
          ...SolutionPreviewQuery
        }
      }
    }
  }
`;
